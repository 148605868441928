<template>
  <v-app>
    <v-container
      class="d-flex flex-row justify-center text-center align-center container"
    >
      <div>
        <h1>Welcome to IGreenConstruction!</h1>
        <p>Coming soon!</p>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { backendTest } from './controllers/requests';

export default {
  name: 'App',

  async created() {
    await backendTest();
  },
};
</script>

<style>
.container {
  height: 100%;
}
</style>

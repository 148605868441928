import apiClient from '../../api/services/apiService';

export const backendTest = () => {
    // Make a GET request to the backend API
    apiClient.get('/hello')
        .then(response => {
            console.log(response.data); // Output: "Hello from the backend!"
        })
        .catch(error => {
            console.error(error);
        });
}
